import img1 from '../../../assets/images/programa-barbara/barbara.png';
import img2 from '../../../assets/images/programa-barbara/element-2.png';

const ProgramSection = () => {
    return(
        <div className="container-fluid mx-0 px-0 ptb--100" id="program-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <h3>Conheça a Mentora</h3>
                            <p>Bárbara é Administradora pela UFES, pós-graduanda em Tech Strategy pela FIA Business School e tem vasta experiência em inovação com empresas renomadas como Petrobras, Unimed e Sebrae. </p>
                            <div className="row">
                                <div className="col-6 h-100">
                                    <p className='mb-5'>Sua paixão por ajudar osoutros a alcançarem seusobjetivos é o que torna essa mentoria única</p>
                                    <p className='fw-light fs-1 mt-5 mb-0'>#InovaçãoEm<strong>Ação</strong></p>
                                </div>
                                <div className="col-6">
                                    <div className="box-image" id='img-1'>
                                        <img src={img1} alt='foto barbara' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 mt-5 mt-md-0">
                        <div className="card">
                            <h3>Estrutura do Programa</h3>
                            <p className='mb-0'>O programa conta com 10 sessões interativas:</p>
                            <ul>
                                <li className='fw-light mb-1 lh-sm'>6 Sessões de Construção e Validação (9 horas)</li>
                                <li className='fw-light mb-1 lh-sm'>4 Sessões de Modelagem de Negócios e Go-to-Market (6 horas)</li>
                                <li className='fw-light mb-1 lh-sm'>15 horas de mentorias no total.</li>
                            </ul>
                            <div className="row">
                                <div className="col-6 h-100">
                                    <p className='mb-5'>Sua paixão por ajudar osoutros a alcançarem seusobjetivos é o que torna essa mentoria única</p>
                                    <p className='fw-light fs-2 fs-md-1 mt-5 mb-0'><strong>#Inovação</strong>Em<strong>Ação</strong></p>
                                </div>
                                <div className="col-6">
                                    <div className="box-image" id='img-2'>
                                        <img src={img2} alt='foto barbara' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgramSection;