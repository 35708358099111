import FooterOne from '../../common/footer/FooterOne';
import HeaderTwo from '../../common/header/HeaderTwo';
import SEO from '../../common/SEO';
import HeroSection from './components/HeroSection';
import PricingSection from './components/PricingSection';
import ProgramSection from './components/ProgramSection';
import './styles/styles.scss'

const ProgramaBarbara = () => {
    return(
        <div className='container-fluid mx-0 px-0' id='programa-barbara'>
            <SEO title="Transforme sua ideia em uma carreira de sucesso" />
            <HeaderTwo styles="header-style-2"/>
            <HeroSection />
            <ProgramSection />
            <PricingSection />
            <FooterOne />
        </div>
    )
}

export default ProgramaBarbara;