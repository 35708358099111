import { IoIosArrowDropdown } from "react-icons/io";

const HeroSection = () => {
    return(
        <div className="container-fluid mx-0 px-0 pt--100 pb--50" id="hero-section-barbara">
            <div className="container ptb--100" id="element-container">
                <div className="row">
                    <h1 className="text-center text-white">Transforme Sua Ideia em um Produto de Sucesso!</h1>
                    <p className="text-center text-white">Junte-se ao programa de mentoria da Academy e aprenda a desenvolver e validar seu produto ou serviço inovador.</p>
                </div>
                <div className="row mt-5">
                    <a href="#program-section">
                        <div className="box-icon">
                            <IoIosArrowDropdown className="icon text-white" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default HeroSection;