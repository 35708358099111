import { IoIosArrowDropright } from 'react-icons/io';
import logo from '../../../assets/images/programa-barbara/academy.png'

const PricingSection = () => {
    return(
        <div className="container-fluid mx-0 px-0 ptb--50" id="pricing-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-10 mx-auto">
                        <div className="card">
                            <div className='d-flex justify-content-center'>
                                <div className="box-image">
                                    <img src={logo} alt='logomarca academy' />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-7 text-center">
                                    <h2 className="h4">Invista no Seu Futuro com um Desconto Exclusivo</h2>
                                </div>
                                <div className="col-12 col-md-5 text-center">
                                    <p className="lh-sm mb-0 fw-light">de R$7.500, por apenas</p>
                                    <h3 className="mb-0">R$ 3.000,00</h3>
                                    <p className="lh-sm mb-0 fw-light">ou 12x de R$255,00!</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>
                                <a href='https://painel.academyabroad.com.br/checkout/mentoria-em-grupo/transforme-sua-ideia-em-um-produto-de-sucesso'>
                                    <div className='button'>
                                        <div className='row'>
                                            <div className='col-2'>
                                                <IoIosArrowDropright className='icon' />
                                            </div>
                                            <div className='col'>
                                                <span className='fs-5'>GARANTA SUA VAGA AGORA!</span>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PricingSection;